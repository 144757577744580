<template>
	<!-- MOBILE NAV -->
	<v-toolbar-items v-if="$vuetify.breakpoint.smAndDown" cols="6" md="6" id="menu-nav-mobile">
		<div class="burger-container">
			<img src="@/assets/icons/open-menu.svg" alt="menu" style="width: 25px" @click="openMenu = !openMenu" />
			<v-list v-if="openMenu === true">
				<div class="nav-menu rounded shadow-blue-not-openned">
					<v-list-item class="d-flex flex-column px-0 pt-3">
						<v-list-item-title>
							<router-link :to="{ name: 'TestHomePage' }" class="navBarLink purpleColor">
								<span @click="openMenu = false">Mes défis</span>
							</router-link>
						</v-list-item-title>

						<v-spacer></v-spacer>
						<a href="https://www.orthographiq.com/?utm_source=from_test_dashboard" class="navBarLink" target="_blank">
							<span @click="openMenu = false">Découvrir la formation</span>
						</a>
						<v-spacer></v-spacer>
						<a class="navBarLink">
							<span @click="logout()">Déconnexion</span>
						</a>
					</v-list-item>
				</div>
			</v-list>
		</div>
	</v-toolbar-items>
	<!-- DESKTOP NAV -->
	<v-toolbar-items v-else cols="6" md="6" id="menu-nav">
		<div id="menu-nav-desktop" class="d-flex align-center justify-center">
			<router-link
				:to="{ name: 'TestHomePage' }"
				class="pl-3 pr-3 navBarLink"
				:class="{ purpleColor: currentRoute.name == 'TestHomePage' }"
			>
				<span>Mes défis</span>
			</router-link>
			<a
				href="https://www.orthographiq.com?utm_source=from_test_dashboard"
				class="pl-3 pr-3 navBarLink"
				target="_blank"
			>
				<span>Découvrir la formation</span>
			</a>
			<a class="pl-3 pr-8 navBarLink" @click="logout()">
				<span>Déconnexion</span>
			</a>
		</div>
	</v-toolbar-items>
</template>

<script>
export default {
	name: 'OnlyHaveTestNav',
	data() {
		return {
			openMenu: false,
		};
	},
	props: {
		currentRoute: Object,
	},
	methods: {
		closeMenu() {
			this.openMenu = false;
		},
		logout() {
			this.$store.dispatch('accountManager/logout');
			this.$router.go('/');
		},
	},
};
</script>

<style scoped lang="scss">
#menu-nav-desktop {
	a {
		&:not(:last-child) {
			border-right: 1px solid #989fa9;
		}
	}

	a {
		color: #989fa9 !important;
	}

	a:hover {
		color: #686bdf !important;
	}

	.navBarLink {
		font-weight: 500;
		letter-spacing: 0.0892857143em;
		text-transform: uppercase;
		font-size: 12px;
	}

	.purpleColor {
		color: #686bdf !important;
	}
}
#menu-nav-mobile {
	a {
		color: #989fa9 !important;
	}
	a:hover {
		color: #686bdf !important;
	}

	.navBarLink {
		font-weight: 500;
		letter-spacing: 0.0892857143em;
		text-transform: uppercase;
		font-size: 12px;
	}

	.purpleColor {
		color: #686bdf !important;
	}

	.burger-container {
		display: flex;
		.nav-menu {
			min-width: 180px;
			background-color: white;
			padding: 16px;
			position: absolute;
			right: 16px;
			top: 60px;

			.v-list-item {
				display: flex;
				gap: 8px;
			}

			.v-list-item__title {
				display: flex;
				width: 100%;
			}

			.v-list-item::after {
				display: none;
			}
		}
	}
}
</style>
