import axios from 'axios';

const state = () => ({
	test: null,
	testBank: null,
	testTracks: null,
});

const getters = {
	test: (state) => {
		return state.test;
	},
};
const mutations = {
	setTest(state, test) {
		state.test = test;
	},
	setTestBank(state, testBank) {
		state.testBank = testBank;
	},
	setTestTracks(state, testTracks) {
		state.testTracks = testTracks;
	},
	clearStore(state) {
		state.test = null;
		state.testBank = null;
		state.testTracks = null;
	},
};
const actions = {
	/** Fetch test also fetch test bank to have detail */
	async fetchAndSetTest(store, { testId }) {
		if (localStorage.getItem('token') != null) {
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		}
		const testRes = await axios.get('/api/tests/' + testId);
		const test = testRes.data.test;

		const testBankRes = await axios.get('/api/testBanks/' + test.test_bank_id);
		const testBank = testBankRes.data.testBank;

		store.commit('setTest', test);
		store.commit('setTestBank', testBank);
		return { test, testBank };
	},
	async checkUserIsInTest(store, { testId, userId }) {
		const res = await axios.post('/api/tests/check-user-in-test', { testId, userId });
		return res.data.isInTest;
	},
	async fetchAndSetTestTracks(store, { testBankId }) {
		const res = await axios.get(`/api/testBanks/${testBankId}/tracks`);
		store.commit('setTestTracks', res.data.testTracks);
		return res.data.testTracks;
	},
	async fetchAllUserTests(store, { userId }) {
		const res = await axios.get(`/api/tests/user/${userId}`);
		return res.data.tests;
	},
	async searchTestBanks(store, { testBankIds }) {
		if (localStorage.getItem('token') != null) {
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		}
		const res = await axios.post('/api/testBanks/search', { ids: testBankIds });
		return res.data.testBanks;
	},
	async fetchTestBankById(store, { testBankId }) {
		if (localStorage.getItem('token') != null) {
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		}
		const res = await axios.get('/api/testBanks/' + testBankId);
		return res.data.testBank;
	},
	clear(store) {
		store.commit('clearStore');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
